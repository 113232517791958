import '../styles/Landing.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowUp } from '@fortawesome/free-solid-svg-icons';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";

function Landing() {
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();

    function toggleDefinition() {
        setOpen(!open);
    }

    return (
        <div className="main-container">
            <div className="top-container">
                <div className="title-container" onClick={toggleDefinition}>
                    <img src={'/images/logo-noBack.png'} alt={'logo'} className={'logo'}/>
                    <>
                        <h1 className="title">sciauerta</h1>
                        {/*<FontAwesomeIcon className={`${open ? 'rotate-180' : 'rotate-180-inverted'} arrow-icon`}
                                         icon={faCircleArrowUp} size="2x" color="black"/>*/}
                    </>
                </div>
                <div>
                    <LanguageSwitcher/>
                </div>
            </div>


            <div className="definizione-container">
                {/* ${open ? 'definizione-open' : 'definizione-closed'}*/}
                <p className={`definizione`}>
                    <span style={{fontWeight: 'bold'}}>/ʃauɛrta/</span> {t('definizione')}
                </p>
            </div>

            <div className="name-container">
                <h1 className="name">azienda agricola sciauerta</h1>
            </div>


        </div>
    )
}

export default Landing;