import React from 'react';
import { useTranslation } from 'react-i18next';
import {Select, MenuItem, FormControl, InputLabel, InputAdornment} from '@mui/material';
import {Language} from "@mui/icons-material";

function LanguageSwitcher() {
    const { i18n } = useTranslation();

    const handleChange = (event) => {
        i18n.changeLanguage(event.target.value);
    };

    return (
        <Select
            labelId="language-select-label"
            id="language-select"
            value={i18n.language}
            startAdornment={<InputAdornment position="start" ><Language  /></InputAdornment>}
            onChange={handleChange}
            variant="standard"
        >
            <MenuItem value={'it'}>It</MenuItem>
            <MenuItem value={'en'}>En</MenuItem>
            <MenuItem value={'de'}>De</MenuItem>
        </Select>
        /*<FormControl variant="outlined" fullWidth sx={{display:'flex', flexDirection:'row'}}>
            <FontAwesomeIcon icon={faGlobe} style={{ marginRight: 8 }} />
            <Select
                labelId="language-select-label"
                id="language-select"
                value={i18n.language}
                onChange={handleChange}
            >
                <MenuItem value="it">It</MenuItem>
                <MenuItem value="en">En</MenuItem>
                <MenuItem value="de">De</MenuItem>
            </Select>
        </FormControl>*/
    );
}

export default LanguageSwitcher;
