
import territorioPageStyle from "../styles/TerritorioPage.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {Trans} from "react-i18next";

function TerritorioPage() {
    const navigate = useNavigate(); // Inizializza useNavigate

    return(
        <div className={territorioPageStyle.mainContainer}>
            <div className={territorioPageStyle.backArrow} style={{position: 'absolute', top: '20px', left: '20px', cursor: 'pointer'}} onClick={() => {
                navigate('/')
            }}>
                <FontAwesomeIcon icon={faCircleArrowLeft} className={territorioPageStyle.backArrowImg}  color="#fff" fontSize={'35px'}/>
            </div>
            <div className={territorioPageStyle.contentContainer}>
                <div className={territorioPageStyle.content}>
                    <p>
                        <Trans i18nKey="territorio.contenuto1" components={{br: <br/>}}/>
                    </p>
                    <h1 style={{margin: 'auto', textAlign: 'center'}}>-</h1>
                    <p>
                        <Trans i18nKey="territorio.contenuto2" components={{br: <br/>}}/>
                    </p>
                </div>

            </div>
            <div className={territorioPageStyle.imageContainer}>
                <img src={"/images/vigna-2.jpg"} className={territorioPageStyle.image}/>
            </div>
        </div>
    )
}

export default TerritorioPage;