import "../styles/StoriaPage.css"
import {Trans} from "react-i18next";
import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowLeft} from "@fortawesome/free-solid-svg-icons";

function StoriaPage() {
    const { pathname } = useLocation();
    const navigate = useNavigate(); // Inizializza useNavigate

    useEffect(() => {
        // Controlla se il percorso è quello della pagina specifica
        if (pathname === "/storia") {
            window.scrollTo(0, 0);
        }
    }, [pathname]);
    return (
        <div className="st-page-main-container">
            <div  style={{position: 'fixed', top: '20px', left: '20px', cursor: 'pointer',zIndex:'1000'}} onClick={() => {
                navigate('/')
            }}>
                <FontAwesomeIcon icon={faCircleArrowLeft} className={'st-page-back'} color="#fff" fontSize={'35px'}/>
            </div>
            {/*<----------NONNO TAPPO E NONNA ZENA---------->*/}
            <div className="st-page-container" style={{backgroundColor: '#13220d'}}>
                <div className="st-page-text-container">
                    <h1 className="st-page-title" style={{color: '#fff'}}>
                        <Trans i18nKey="storia.titolo1" components={{br: <br/>}}/>
                    </h1>
                    <p className="st-page-text" style={{color: '#fff'}}>
                        <Trans i18nKey="storia.contenuto1" components={{br: <br/>}}/>
                    </p>
                </div>
                <div className="st-page-img-1-container">
                    <img src={"/images/TappoEZena.png"} className="st-page-img-1"/>
                </div>
            </div>
            {/*<----------FERDINANDO E MARILINA---------->*/}
            <div className="st-page-container" style={{backgroundColor: '#dbd5a5'}}>
                <div className="st-page-img-1-container ">
                    <img src={"/images/FerdinandoEMarilina.jpg"} className="st-page-img-1 st-page-img-2"/>
                </div>
                <div className="st-page-text-container">
                    <h1 className="st-page-title" style={{color: '#13220d'}}>
                        <Trans i18nKey="storia.titolo2" components={{br: <br/>}}/>
                    </h1>
                    <p className="st-page-text" style={{color: '#13220d'}}>
                        <Trans i18nKey="storia.contenuto2" components={{br: <br/>}}/>
                    </p>
                </div>
            </div>
            {/*<----------EDO E MANU---------->*/}
            <div className="st-page-container" style={{backgroundColor: '#13220d'}}>
                <div className="st-page-text-container">
                    <h1 className="st-page-title" style={{color: '#fff'}}>
                        <Trans i18nKey="storia.titolo3" components={{br: <br/>}}/>
                    </h1>
                    <p className="st-page-text" style={{color: '#fff'}}>
                        <Trans i18nKey="storia.contenuto3" components={{br: <br/>}}/>
                    </p>
                </div>
                <div className="st-page-img-1-container">
                    <img src={"/images/EdoEManu.jpg"} className="st-page-img-3"/>
                </div>
            </div>


        </div>
    )
}

export default StoriaPage;