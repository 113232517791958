
import style from '../styles/ProgettiPage.module.css';
import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {faCircleArrowLeft, faCircleArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function ProgettiPage() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate(); // Inizializza useNavigate

    return(
        <div className={style.mainContainer}>
            <div style={{position:'absolute', top:'20px', left:'20px', cursor:'pointer'}} onClick={()=>{navigate('/')}}>
                <FontAwesomeIcon icon={faCircleArrowLeft}  color="#fff" fontSize={'35px'}/>
            </div>
            <div className={style.imageContainer}>
                <h1 className={style.imageText}>
                    <Trans i18nKey="progetti.contenuto1" components={{br: <br/>}}/>
                </h1>
            </div>

            <div className={style.contentContainer}>
                <p className={style.contentText}>
                    <Trans i18nKey="progetti.contenuto2" components={{br: <br/>}}/>
                </p>
            </div>
        </div>
    )
}

export default ProgettiPage;