import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import StoriaPage from "./pages/StoriaPage";
import TerritorioPage from "./pages/TerritorioPage";
import ProgettiPage from "./pages/ProgettiPage";
import Etichetta from "./pages/Etichetta";


function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/storia" element={<StoriaPage />} />
                <Route path="/territorio" element={<TerritorioPage />} />
                <Route path="/progetti" element={<ProgettiPage />} />
                <Route path="/etichetta" element={<Etichetta />} />
            </Routes>
        </Router>
    );
}

export default App;
