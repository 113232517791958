import Landing from "../components/Landing";
import Storia from "../components/Storia";
import Territorio from "../components/Territorio";
import Progetti from "../components/Progetti";
import Prodotti from "../components/Prodotti";
import Contatti from "../components/Contatti";

function Homepage() {
    return (
        <div style={{overflowX:"hidden"}}>
            <Landing/>
            <Storia/>
            <Territorio/>
            <Progetti/>
            <Prodotti />
            <Contatti />
        </div>
    )
}

export default Homepage;