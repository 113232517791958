import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowRight} from "@fortawesome/free-solid-svg-icons";

function Progetti() {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate(); // Inizializza useNavigate

    const handleNavigation = (path) => {
        navigate(path); // Naviga verso /storia
    };

    return (
        <div className="storia-main-container" style={{backgroundColor:'#13220d'}}>
            <div className="storia-content-container">
                <h1 className="storia-title" style={{fontSize:'3.5rem', color:'#fff'}}>
                    <Trans i18nKey="progetti.titolo" components={{ br: <br /> }} />
                </h1>
                <p className="storia-subtitle" style={{color:'#fff'}}><Trans i18nKey="progetti.sottotitolo" components={{ br: <br /> }} /></p>
                <div className="storia-action-container" onClick={()=>handleNavigation('/progetti')}>
                    <h2 className="storia-action-text" style={{color: '#fff'}}>{t('storia.action')}</h2>
                    <FontAwesomeIcon icon={faCircleArrowRight} size="xl" color="#fff"/>
                </div>
            </div>
        </div>
    )
}

export default Progetti;