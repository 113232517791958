import style from "../styles/Contatti.module.css";
import {Trans, useTranslation} from "react-i18next";


export default function Contatti() {
    const { t, i18n } = useTranslation();

    return (
        <div className={style.mainContainer}>
            <img src={'/images/logo-noBack.png'} className={style.logo} alt={'logo'}/>
            <div className={style.textContainer}>
                <p className={style.title}>
                    <Trans i18nKey="contatti.titolo" components={{ br: <br /> }} />
                </p>
                <p className={style.description}>
                    (+39) 3343820420 <br/>
                    <a href={'mailto:cantinasciauerta@gmail.com'} style={{color:'#fff'}}>cantinasciauerta@gmail.com</a> <br/>
                    c.da Grazie n.12 Cossignano (AP)
                </p>
            </div>
        </div>
    )
}