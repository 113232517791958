import style from '../styles/Etichetta.module.css'

export default function Etichetta() {
    return(
        <div className={style.mainContainer}>
            <div className={style.labelContainer} id="bottle">
                <img className={style.bottleImg} src={"/images/Az_Agr_Sciauerta_bottiglia_DEF.png"} alt={'bottiglia'} />
            </div>

            <div className={style.tableContainer}>
                <table className={style.styledTable}>
                    <thead>
                    <tr>
                        <th>Bottiglia</th>
                        <th>Capsula</th>
                        <th>Tappo <span style={{color: 'red'}}>*</span> </th>
                        <th>Cartone</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td> <img className={style.recycleIcon} src={"/images/icone/Recycling-Code-71-bottiglia.png"} alt={'recycle icon'} /></td>
                        <td>PI</td>
                        <td><img className={style.recycleIcon} src={"/images/icone/Recycling-Code-04.png"} alt={'recycle icon'} /></td>
                        <td><img className={style.recycleIcon} src={"/images/icone/Recycling-Code-20.png"} alt={'recycle icon'} /></td>
                    </tr>
                    <tr>
                    <td>Vetro</td>
                        <td>Indifferenziata</td>
                        <td>Plastica</td>
                        <td>Carta</td>
                    </tr>
                    </tbody>
                </table>

            </div>
            <div className={style.infoContainer}>
                <p> <span style={{color:'red'}}>*</span>
                    Tappo Nomacorc Ocean, prodotto da rifiuti OBP (Ocean Bound Plastic), scarti di plastica recuperati nelle regioni costiere
                    dove presentano il più alto rischio di inquinamento.
                </p>
                <p>
                    Per maggiori informazioni visita <a href="https://www.vinventions.com/it/nomacorcocean" target={'_blank'}>Vinventions</a>.
                </p>
            </div>
        </div>
    )
}