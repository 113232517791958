import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationIT from './locales/it/translation.json';
import translationDE from './locales/de/translation.json';

// Configura le risorse per ogni lingua
const resources = {
    en: { translation: translationEN },
    it: { translation: translationIT },
    de: { translation: translationDE }
};

i18n
    .use(initReactI18next) // Collegamento di react-i18next a i18next
    .init({
        resources,
        lng: 'it', // Lingua predefinita
        fallbackLng: 'en', // Lingua di fallback
        interpolation: {
            escapeValue: false // react già gestisce la sicurezza nell'output
        }
    });

export default i18n;
